
body {
  background: url('./public/background.jpg') no-repeat center center fixed; 
  background-size: cover; /* Cover the entire page */
  overflow: auto; /* Prevent scrollbars if the image is large */
}

.Rnd {
  box-shadow: 14px 15px 37px -11px rgba(0,0,0,0.61); /* Soft shadow for depth */
}

.profile-card {
  position: relative;
}

.delete-button {
  xposition: absolute;
  top: 5px;
  right: 5px;
  background-color: #ff4d4d;
  color: white;
  border: none;
  padding: 5px 10px;
  cursor: pointer;
  height: 2em;
}

.delete-button:hover {
  background-color: #ff0000;
}

/* Add this to your App.css or a dedicated CSS file */

.teacher-card {
  margin: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  padding: 10px;
  display: flex;
  align-items: center;
  background: #f9f9f9; /* Light background for better readability */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Soft shadow for depth */
  transition: background-color 0.3s ease; /* Transition for smooth background color change */
}

.teacher-card:hover {
  background-color: #ffdddd; /* Light red background on hover */
}

.teacher-card h3 {
  font-weight: bold;
}

.teacher-card img {
  width: 60px;
  height: 60px;
  border-radius: 50%;
  margin-right: 10px;
}

.teacher-card div {
  flex-grow: 1; /* Allows the text div to fill remaining space */
}

.teacher-card.selected {
  background-color: #ddffdd; /* Light red background on hover */
}

.teachers-list {
  height: 90%;
  overflow: auto;
  text-align: left;
}

/* Add this to your App.css or a separate CSS file */
.quiz-container {
  padding: 20px;
  background-color: #f8f8f8;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0,0,0,0.2);
}

.question-section, .score-section {
  margin-bottom: 20px;
  font-size: 20px;
  font-weight: bold;
}

.answer-section button {
  padding: 10px;
  margin: 5px;
  background-color: #4CAF50;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.answer-section button:hover {
  background-color: #45a049;
}


/* Add this to your App.css or a similar file */
.course-card {
  margin: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  padding: 10px;
  background: #f9f9f9; /* Light background for better readability */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Soft shadow for depth */
  transition: background-color 0.3s ease; /* Transition for smooth background color change */
  
}

.course-card:hover {
  background-color: #ffdddd; /* Light red background on hover */
}

.course-card.selected {
  background-color: #ddffdd; /* Light red background on hover */
}

.courses-list {
  height: 90%;
  overflow: auto;
  text-align: left;
}

.course-card h3 {
  font-weight: bold;
}


.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.content {
  overflow-y: auto;
}


.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}


/* Add this to your App.css */
.notes-container {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.toolbar button {
  margin-right: 5px;
}

.editable {
  flex: 1;
  overflow-y: auto;
  padding: 10px;
  border: 1px solid #ccc;
  margin-top: 5px;
}

/* Optional: Additional styles for your Quill editor container */
.notes-container {
  padding: 10px;
}

/* App.css or the relevant CSS file */

.dropzone {
  cursor: pointer;
  transition: border-color 0.3s ease-in-out;
  text-align: center;
}

.dropzone:hover {
  border-color: #aaa; /* Color change on hover */
}

.create-course-button {
  padding: 10px 15px;
  background-color: #4CAF50; /* Green background for the button */
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  margin-top: 10px;
}

.create-course-button:hover {
  background-color: #45a049; /* Darker green on hover */
}

/* Ensure list items have no bullets and the file list is not padded */
.dropzone ul {
  list-style-type: none;
  padding: 0;
}

.dropzone li {
  font-size: 0.8em;
  margin: 5px 0;
}

.green-button {
    background-color: #4CAF50;
    color: white;
    padding: 10px 15px;
    border: none;
    border-radius: 4px;
    margin: 10px 5px;
    cursor: pointer;
}

.notes-container {
  height: 90%;
  overflow: auto;
  text-align: center;
}


.message {
  
  width: 80%;
}

.justify-start {
  justify-content: flex-start; /* Aligns children (including .message-text) to the left */
}

.justify-end {
  justify-content: flex-end; /* Aligns children to the right */
}

.bg-blue-200 {
  background-color: #bfdbfe; /* Example blue background for user messages */
}

.bg-gray-200 {
  background-color: #e2e8f0; /* Example gray background for bot messages */
}

.text-right {
  text-align: right;
}

.text-left {
  text-align: left;
}

/* ...other styles... */
.create-user-form {
  max-width: 300px;
  margin: auto;
  padding: 20px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.create-user-form h2 {
  text-align: center;
}

.create-user-form form {
  display: flex;
  flex-direction: column;
}

.create-user-form label {
  margin-bottom: 5px;
}

.create-user-form input {
  margin-bottom: 10px;
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.create-user-form button {
  padding: 10px;
  background-color: #4CAF50;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.create-user-form button:disabled {
  background-color: #999;
}

.error {
  color: red;
  font-size: 0.8em;
}

.login-form {
  max-width: 300px;
  margin: auto;
  padding: 20px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.login-form h2 {
  text-align: center;
}

.login-form form {
  display: flex;
  flex-direction: column;
}

.login-form label {
  margin-bottom: 5px;
}

.login-form input {
  margin-bottom: 10px;
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.login-form button {
  padding: 10px;
  background-color: #4CAF50;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.login-form button:disabled {
  background-color: #999;
}

.error {
  color: red;
  font-size: 0.8em;
}

.create-user-form, .login-form {
  background-color: #fff;
  margin-top: 10px;
}

.prompt-editor {
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 10px;
  background-color: #f9f9f9;
}

.prompt-editor h2 {
  text-align: center;
  color: #333;
}

.prompt-editor form {
  display: flex;
  flex-direction: column;
}

.prompt-editor label {
  margin-bottom: 10px;
  font-weight: bold;
}

.prompt-editor input[type="text"],
.prompt-editor textarea,
.prompt-editor select {
  padding: 10px;
  margin-top: 5px;
  margin-bottom: 15px;
  border: 1px solid #ccc;
  border-radius: 5px;
}

.prompt-editor button {
  padding: 10px;
  margin-top: 10px;
  border: none;
  border-radius: 5px;
  background-color: #4CAF50;
  color: white;
  cursor: pointer;
  font-size: 16px;
}

.prompt-editor button:hover {
  background-color: #45a049;
}

.prompts-list {
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 10px;
  background-color: #f9f9f9;
  overflow-y: auto;
  height: 100%;
}

.prompts-list h2 {
  text-align: center;
  color: #333;
}

.filter-select {
  text-align: center;
  margin-bottom: 20px;
}

.filter-select label {
  font-weight: bold;
  margin-right: 10px;
}

.filter-select select {
  padding: 5px;
}

.prompts-list ul {
  list-style: none;
  padding: 0;
}

.prompts-list .prompt-item {
  padding: 10px;
  margin-bottom: 10px;
  border: 1px solid #ddd;
  border-radius: 5px;
  background-color: #fff;
  cursor: pointer;
  transition: background-color 0.3s;
}

.prompts-list .prompt-item:hover {
  background-color: #f1f1f1;
}

.prompts-list .prompt-item.disabled {
  background-color: #f9f9f9;
  color: #999;
}

.prompts-list .prompt-name {
  font-weight: bold;
  font-size: 16px;
  margin-bottom: 5px;
}

.prompts-list .prompt-timestamp {
  font-size: 12px;
  color: #999;
  margin-bottom: 10px;
}

.prompts-list .prompt-description {
  font-size: 14px;
  color: #555;
}

.preferences-container h2 {
  text-align: center;
  color: #333;
  margin-bottom: 20px;
}

.preferences-container input[type="text"] {
  padding: 10px;
  margin-bottom: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  width: calc(50% - 12px);
  margin-right: 10px;
}

.preferences-container button {
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  background-color: #4CAF50;
  color: white;
  cursor: pointer;
  font-size: 16px;
  margin-bottom: 10px;
}

.preferences-container button:hover {
  background-color: #45a049;
}

.preferences-container ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.preferences-container li {
  padding: 10px;
  margin-bottom: 10px;
  border: 1px solid #ddd;
  border-radius: 5px;
  background-color: #fff;
  display: flex;
  align-items: center;
  justify-content: space-between;
  transition: background-color 0.3s;
}

.preferences-container li:hover {
  background-color: #f1f1f1;
}

.preferences-container .edit-input {
  padding: 8px;
  margin-right: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  flex: 1;
}

.preferences-container .edit-buttons {
  display: flex;
}

.preferences-container .edit-buttons button {
  margin-left: 5px;
}

.preferences-container .edit-buttons button:first-child {
  margin-left: 0;
}

.preferences-container {
  padding: 20px;
  background-color: #f5f5f5;
  border-radius: 5px;
}

.preferences-container h2 {
  margin-bottom: 20px;
}

.error {
  color: red;
  margin-bottom: 10px;
}

.add-preference {
  display: flex;
  margin-bottom: 20px;
}

.add-preference input {
  flex: 1;
  margin-right: 10px;
  padding: 5px;
}

.add-preference button {
  padding: 5px 10px;
}

.preferences-list {
  list-style-type: none;
  padding: 0;
}

.preference-item {
  background-color: white;
  border: 1px solid #ddd;
  margin-bottom: 10px;
  padding: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.preference-content {
  flex: 1;
}

.preference-actions button {
  margin-left: 10px;
}

.edit-input {
  margin-right: 10px;
  padding: 5px;
}

.edit-buttons button {
  margin-left: 5px;
}

.prompt-editor {
  padding: 20px;
  background-color: #f5f5f5;
  border-radius: 5px;
}

.prompt-editor h2 {
  margin-bottom: 20px;
}

.error {
  color: red;
  margin-bottom: 10px;
}

.success {
  color: green;
  margin-bottom: 10px;
}

.form-group {
  margin-bottom: 15px;
}

.form-group label {
  display: block;
  margin-bottom: 5px;
}

.form-group input[type="text"],
.form-group textarea,
.form-group select {
  width: 100%;
  padding: 8px;
  border: 1px solid #ddd;
  border-radius: 4px;
}

.form-group textarea {
  height: 100px;
}

.button-group {
  display: flex;
  justify-content: space-between;
}

.button-group button {
  padding: 10px 20px;
  background-color: #4CAF50;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.button-group button:disabled {
  background-color: #cccccc;
  cursor: not-allowed;
}

.button-group button:not(:last-child) {
  margin-right: 10px;
}

.prompts-list {
  padding: 20px;
  background-color: #f5f5f5;
  border-radius: 5px;
}

.prompts-list h2 {
  margin-bottom: 20px;
}

.prompts-controls {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
}

.filter-select, .search-input {
  padding: 8px;
  border: 1px solid #ddd;
  border-radius: 4px;
}

.prompts-table {
  width: 100%;
  border-collapse: collapse;
}

.prompts-table th, .prompts-table td {
  padding: 10px;
  border: 1px solid #ddd;
  text-align: left;
}

.prompts-table th {
  background-color: #f0f0f0;
  cursor: pointer;
}

.prompts-table th:hover {
  background-color: #e0e0e0;
}

.prompt-item {
  cursor: pointer;
}

.prompt-item:hover {
  background-color: #f0f0f0;
}

.pagination {
  margin-top: 20px;
  display: flex;
  justify-content: center;
}

.pagination button {
  margin: 0 5px;
  padding: 5px 10px;
  border: 1px solid #ddd;
  background-color: white;
  cursor: pointer;
}

.pagination button.active {
  background-color: #4CAF50;
  color: white;
}

.error {
  color: red;
  margin-bottom: 10px;
}

.auth-form {
  max-width: 300px;
  margin: auto;
  padding: 15px;
  background-color: white;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.auth-form h2 {
  text-align: center;
  margin-bottom: 15px;
  font-size: 1.5em;
}

.auth-form .form-group {
  margin-bottom: 10px;
}

.auth-form label {
  display: block;
  margin-bottom: 3px;
  font-size: 0.9em;
}

.auth-form input[type="email"],
.auth-form input[type="password"] {
  width: 100%;
  padding: 8px;
  border: 1px solid #ddd;
  border-radius: 4px;
  background-color: #f0f8ff;
}

.auth-form button {
  width: 100%;
  padding: 8px;
  margin-top: 10px;
  background-color: #4CAF50;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 1em;
}

.auth-form .remember-me {
  display: flex;
  align-items: center;
  margin-top: 5px;
  font-size: 0.9em;
}

.auth-form .remember-me input {
  margin-right: 5px;
}

.auth-form .remember-me input[type="checkbox"] {
  width: auto;
  margin-right: 5px;
}

.profiles-container {
  display: flex;
  height: 100%;
  background-color: #f5f5f5;
  border-radius: 10px;
  overflow: hidden;
}

.profiles-list {
  flex: 1;
  overflow-y: auto;
  padding: 20px;
  border-right: 1px solid #ccc;
}

.profiles-list h2 {
  text-align: center;
  color: #333;
  margin-bottom: 20px;
}

.profile-card {
  display: flex;
  text-align: left;
  padding: 10px;
  margin-bottom: 10px;
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.profile-card {
  cursor: grab;
}

.profile-card:active {
  cursor: grabbing;
}

.profile-card:hover {
  background-color: #f0f0f0;
}

.profile-card.selected {
  background-color: #e0e0e0;
}

.profile-card img {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  margin-right: 10px;
  object-fit: cover;
}

.profile-card h3 {
  margin: 0;
  font-size: 16px;
}

.profile-card p {
  margin: 5px 0 0;
  font-size: 14px;
  color: #666;
}

.profile-editor {
  flex: 2;
  padding: 20px;
  overflow-y: auto;
}

.profile-editor h2 {
  text-align: center;
  color: #333;
  margin-bottom: 20px;
}

.profile-editor .form-group {
  margin-bottom: 15px;
}

.profile-editor label {
  display: block;
  margin-bottom: 5px;
  font-weight: bold;
}

.profile-editor input[type="text"],
.profile-editor textarea {
  width: 100%;
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.profile-editor textarea {
  height: 100px;
  resize: vertical;
}

.profile-editor textarea.full-background {
  height: 200px;
  resize: vertical;
}

.profile-editor input[type="file"] {
  margin-top: 5px;
}

.profile-editor .current-profile-image {
  display: block;
  max-width: 200px;
  max-height: 200px;
  margin: 10px 0;
  border-radius: 5px;
}

.profile-editor .submit-button {
  display: block;
  width: 100%;
  padding: 10px;
  background-color: #4CAF50;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 16px;
  transition: background-color 0.3s;
}

.profile-editor .submit-button:hover {
  background-color: #45a049;
}

.error, .success {
  margin-bottom: 10px;
  padding: 10px;
  border-radius: 4px;
}

.error {
  background-color: #ffebee;
  color: #c62828;
}

.success {
  background-color: #e8f5e9;
  color: #2e7d32;
}

.generate-image-button {
  display: block;
  width: 100%;
  padding: 10px;
  margin-top: 10px;
  background-color: #3498db;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 16px;
  transition: background-color 0.3s;
}

.generate-image-button:hover {
  background-color: #2980b9;
}

.generate-image-button:disabled {
  background-color: #bdc3c7;
  cursor: not-allowed;
}

.chat-container {
  overflow: hidden;  /* This ensures the rotated div doesn't cause scrollbars */
}

.user-management-container {
  display: flex;
  height: 100%;
  background-color: #f5f5f5;
  border-radius: 10px;
  overflow: hidden;
}

.users-list {
  flex: 1;
  overflow-y: auto;
  padding: 20px;
  border-right: 1px solid #ccc;
}

.users-list h2 {
  text-align: center;
  color: #333;
  margin-bottom: 20px;
}

.user-item {
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 5px;
  margin-bottom: 10px;
  padding: 10px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.user-item:hover {
  background-color: #f0f0f0;
}

.user-item.selected {
  background-color: #e0e0e0;
}

.user-item h3 {
  margin: 0;
  font-size: 16px;
}

.user-item p {
  margin: 5px 0 0;
  font-size: 14px;
  color: #666;
}

.user-editor {
  flex: 2;
  padding: 20px;
  overflow-y: auto;
}

.user-editor h2 {
  text-align: center;
  color: #333;
  margin-bottom: 20px;
}

.user-editor .form-group {
  margin-bottom: 15px;
}

.user-editor label {
  display: block;
  margin-bottom: 5px;
  font-weight: bold;
}

.user-editor input[type="text"],
.user-editor input[type="email"],
.user-editor input[type="password"] {
  width: 100%;
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.user-editor button {
  display: block;
  width: 100%;
  padding: 10px;
  background-color: #4CAF50;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 16px;
  transition: background-color 0.3s;
}

.user-editor button:hover {
  background-color: #45a049;
}

.user-editor button:disabled {
  background-color: #cccccc;
  cursor: not-allowed;
}

.delete-user-button {
  background-color: #ff4d4d;
  color: white;
  border: none;
  padding: 5px 10px;
  cursor: pointer;
  height: 2em;
  margin-top: 5px;
}

.delete-user-button:hover {
  background-color: #ff0000;
}

.create-new-user-button {
  margin-bottom: 10px;
  padding: 10px;
  background-color: #4CAF50;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.create-new-user-button:hover {
  background-color: #45a049;
}

.warning-content p {
    margin-bottom: 1em;
  }
  .warning-content p:last-child {
    margin-bottom: 0;
  }

.user-item.current-user {
  background-color: #e6f7ff;
  border: 1px solid #91d5ff;
}

.current-user-indicator {
  background-color: #1890ff;
  color: white;
  padding: 2px 6px;
  border-radius: 4px;
  font-size: 0.8em;
  margin-left: 10px;
}

.delete-button:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}